import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EmailSignup from '../components/email_signup/email_signup';
import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import {
  emailSignupWrapper,
  latestnewsarticleheadline,
  carouselSlide,
  dateline,
  subhead,
  linkToFaq,
  archiveLinkWrapper,
  archiveLink,
  carouselImage,
  findAFellow,
  legendUnderText,
  legendUnderMap,
  desktopMap
} from './maine.module.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


const Maine = () => {
  const isXs = useMediaQuery('(max-width:599px)');
  const shouldShowComingSoon = false;
  const carouselInterval = isMobile ? 99999999 : 4000;      // workaround for bug: https://github.com/leandrowd/react-responsive-carousel/issues/621

  return (
    <Layout pageTitle="Bendable Maine">

      <div id="page" className="Site" >
        <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

        <header id="masthead" className="Site-header">
          <div className="Site-branding">
            <a href="/" className="custom-logo-link" rel="home"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		</div>
          
                <div className="Community-header">
              <div className="Community-name">Maine</div>  			<div className="back">
                <Link to="/" rel="home">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
                  </svg>
                  &nbsp;Back
                </Link>
              </div>
            </div>
          
        </header>

        <main id="primary" className="Site-main">
          <article id="post-41" className="post-41 communities type-communities status-publish has-post-thumbnail hentry">
            <div className="entry-content">

              <Box 
                sx={{
                  display: shouldShowComingSoon ? 'block' : 'none',
                  paddingTop: { xs: "16px", sm: "50px" },
                  paddingLeft: { xs: "28px", sm: "28px", md: "100px" },
                  backgroundColor: "#95FFEF",
                  height: { xs: "60px", sm: "160px" },
                }}
              >
                <Stack direction="row">
                  <Typography sx={{ 
                    color: "#000000",
                    fontFamily: "Cooper Std, sans-serif",
                    fontSize: { xs: "1.6rem", sm: "3.6rem" },
                  }}>
                    Going live Aug. 23! <img src="" />
                  </Typography>
                  <Box 
                    sx={{
                      paddingTop: { xs: "4px", sm: "10px" },
                      paddingLeft: '10px',
                      height: { xs: "24px", sm: "50px" },
                    }}
                  >
                    { isXs && <StaticImage src="../images/celebration_emoji.png" alt="Celebration Emoji" height={24} />}
                    { !isXs && <StaticImage src="../images/celebration_emoji.png" alt="Celebration Emoji" height={50} />}
                  </Box>
                </Stack>
              </Box>


              <div 
                className="Section flushRight" 
                style={{ backgroundColor: "#f9f4ec", color: "#000000" }}
              >
              
                {/* <div className="Section-content">
                  <h1>The place Maine comes to learn</h1>
                  <div className={emailSignupWrapper} >
                    <EmailSignup source="bendable.com/maine.html" />
                  </div>
                </div> */}

                <div className="Section-content" style={{
                  paddingBottom: '1rem',
                }}>
                  <Typography sx={{ 
                    color: "#38718F",
                    fontFamily: "Cooper Std, sans-serif",
                    fontSize: { xs: "1.6rem", sm: "3.6rem" },
                    lineHeight: { xs: "1.6rem", sm: "3.6rem" },
                    marginBottom: { xs: "1.0rem", sm: "2.0rem" },
                  }}>
                    How Bendable Maine was developed
                  </Typography>

                  <Typography sx={{ 
                    fontFamily: "Sharp Sans",
                    fontSize: { xs: "1rem", sm: "1rem" },
                    lineHeight: { xs: "1.5rem", sm: "1.5rem" },
                    marginBottom: { xs: "1.5rem", sm: "1.5rem" },
                  }}>
                    Over the span of a year, the Maine State Library worked with the Drucker Institute
                    to tailor Bendable for the residents of the state. Below you’ll find some of the
                    resources used during this process.
                  </Typography>

                  <Typography sx={{ 
                    fontFamily: 'Sharp Sans',
                    fontWeight: 'bold',
                    fontSize: { xs: "1rem", sm: "1rem" },
                    lineHeight: { xs: "1.5rem", sm: "1.5rem" },
                    marginBottom: { xs: "0.5rem", sm: "0.5rem" },
                  }}>
                    By the numbers:
                  </Typography>


                  <div style={{
                    fontFamily: 'Sharp Sans',
                    fontSize: { xs: "1rem", sm: "1rem" },
                    lineHeight: { xs: "1.5rem", sm: "1.5rem" },
                  }}>
                    <ul>
                      <li>
                        <strong>600+</strong> Individual residents engaged for their input on design and content
                        curation for Bendable Maine
                      </li>
                      <li>
                        <strong>100+</strong> Meetings with representatives of organizations all over
                        the state–employers, nonprofits, schools and government agencies–to get their input
                      </li>
                      <li>
                        <strong>160+</strong> Organizations represented at those meetings
                      </li>
                    </ul>
                  </div>
                </div>                
      
                <div className="Section-image has-mobile fill">
                  <img width="794" height="721" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Blueberries.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Blueberries.png 794w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Blueberries-300x272.png 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Blueberries-768x697.png 768w" sizes="(max-width: 794px) 100vw, 794px" />		
                </div>
              
                <div className="Section-image-mobile">
                  <img width="2560" height="1707" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-scaled.jpeg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-scaled.jpeg 2560w, https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-300x200.jpeg 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-1024x683.jpeg 1024w, https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-768x512.jpeg 768w, https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-1536x1024.jpeg 1536w, https://bendable.s3.us-west-1.amazonaws.com/marketing/dwp_emhs_blueberry_harvest_080318_COMP01_AND_A-2048x1365.jpeg 2048w" sizes="(max-width: 2560px) 100vw, 2560px" />		
                </div>
              
              </div>





              <div 
                className="Section left" 
                style={{ backgroundColor: "#1B7392", color: "#ffffff" }}
              >
            
                <div className="Section-content">
                  <h2>Building a community of lifelong learning</h2>
                  <p>The <strong>Maine State Library</strong> is working with the <strong>Drucker Institute</strong> to bring Bendable, a lifelong learning system, 
                  to the residents of Maine. But Bendable isn’t being built <em>for</em> the community, it’s being built <em>with</em> the community.</p>
                
                  <div className={linkToFaq}>
                    <Link to="/faq">
                      <div className="Button">Read FAQs</div>
                    </Link>
                  </div>
                
                </div>


            
                <div className="Section-image  ">
                  <img width="715" height="549" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Amber.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/Amber.png 715w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Amber-300x230.png 300w" sizes="(max-width: 715px) 100vw, 715px" />		
                </div>

              </div>





              <div 
                className="Section right" 
                style={{ backgroundColor: "##ffffff", color: "##000000" }}
              >
            
                <div className={findAFellow}>
                  <h2 style={{ color: "#1B7392" }}>Where to find a fellow</h2>
                  <p style={{ fontFamily: 'Sharp Sans', fontSize: '1.4rem' }}>Our Fellows are here to ensure that 
                    Bendable is being shaped by Mainers for Mainers. 
                    They are working with a wide range of organizational stakeholders and individual residents 
                    to co-design Bendable Maine, help curate learning content to meet local interests and needs, 
                    promote the system and integrate it into the lives of Mainers everywhere. Feel free to reach 
                    out to help bring Bendable to your area.</p>

                  <div className={legendUnderText}>
                    <StaticImage src="../images/maine_fellows_map_legend.png" alt="Maine fellows map legend" />
                  </div>
                    
                </div>

                <div className={legendUnderMap}>
                  <div>
                    <StaticImage src="../images/maine_fellows_map.png" alt="Maine fellows map" />
                  </div>
                  <div>
                    <StaticImage src="../images/maine_fellows_map_legend.png" alt="Maine fellows map legend" />
                  </div>
                </div>



                <div className={desktopMap}>
                  <StaticImage src="../images/maine_fellows_map.png" alt="Maine fellows map" />
                </div>

              </div>




              <div 
                className="Section left" 
                style={{ backgroundColor: "#1B7392", color: "#ffffff" }}
              >
            
                <div className="Section-content">
                  <h2>How we choose what to include on Bendable</h2>
                  <p>Bendable Maine is, in large part, a discovery tool—a way for residents to easily find and access the learning 
                  that is just right for them.</p>
                  <p>In turn, curating content—picking which learning resources to offer in the system and from which providers—is 
                  essential for enabling and enhancing that process of discovery. Click below to see the principles that guide us in our curation.</p>
                
                  <div className={linkToFaq}>
                    <a href="https://bendable.s3.us-west-1.amazonaws.com/Bendable+Maine+-+Content+Curation+Principles.pdf" target="_blank" rel="noreferrrer">
                      <div className="Button">Read more</div>
                    </a>
                  </div>
                
                </div>


            
                <div className="Section-image">
                  <img width="715" height="549" src="https://bendable.s3.us-west-1.amazonaws.com/mg_building_the_catalog_715x549.jpg" className="attachment-full size-full" alt="Building the catalog" />		
                </div>

              </div>




              <blockquote className="wp-block-quote"><p>“This opportunity to bring a customizable lifelong learning platform for all Maine people is incredibly exciting. Harnessing the creativity of Maine people, universities and colleges, as well as Maine businesses and nonprofits, will be fundamental in making Bendable Maine a success.”</p><cite><strong>–&nbsp;James Ritter</strong> Maine State Librarian</cite></blockquote>

              <div className="maine-carousel-wrapper" style={{ backgroundColor: '#F4615F' }}>
                <Carousel showStatus={false} autoPlay={!isMobile} interval={carouselInterval} infiniteLoop={true} showThumbs={false}>

                  <div className={carouselSlide}>
                    <div className="News" style={{ paddingTop: '2rem', minHeight: '600px' }}>
                      <h2 className="News-title">Latest News</h2>
                      <div className="News-item">
                        <div className="News-image">
                          {/* <img width="611" height="382" src="/images/dwp_borestone_061417_602_carousel.jpg" className="attachment-full size-full" alt="" 
                            sizes="(max-width: 611px) 100vw, 611px" /> */}
                          <StaticImage src="../images/dwp_borestone_061417_602_carousel.jpg" alt="Woman outdoors in Maine" />
                        </div>
                        <div className="News-body">
                          <div className="News-content">
                            <p className={dateline}>July 19, 2021</p>
                            <h2 className={latestnewsarticleheadline}>The Drucker Institute and Maine State Library Bring Bendable to Maine</h2>
                            {/* <p className={subhead}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                            <div className="News-actions">
                              <a href="https://www.prweb.com/releases/the_drucker_institute_and_maine_state_library_bring_bendable_to_maine/prweb18069654.htm" target="_blank" rel="noreferrer" className="Button">Read more</a>
                              {/* <a href="https://www.drucker.institute/news-post/drucker-institute-and-maine-state-library-bring-bendable-to-maine/" target="_blank" rel="noreferrer" className="Button">Read more</a> */}
                            </div>
                            <div className={archiveLinkWrapper}>
                              <Link to="/maine_digest_archives">
                                <div className={archiveLink}>See older items</div>
                              </Link>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className={carouselSlide}>
                    <div className="News" style={{ paddingTop: '2rem', minHeight: '600px' }}>
                      <h2 className="News-title">Latest News</h2>
                      <div className="News-item">
                        <div className="News-image">
                          {/* 611 x 382 */}
                          <img width="611" height="382" src="https://network.bendable.com/wp-content/uploads/2021/10/dwp_bendable_madelinepointoysters_090821_132.jpg" alt="Fisherman on boat" />
                        </div>
                        <div className="News-body">
                          <div className="News-content">
                            <p className={dateline}>July 2022</p>
                            <h2 className={latestnewsarticleheadline}>Bendable Maine Digest</h2>
                            <p className={subhead}>A monthly update on the lifelong learning system being shaped by Mainers for Mainers</p>
                            <div className="News-actions">
                              <Link to="/maine_digest_2022_07">
                                <div className="Button">Read more</div>
                              </Link>
                            </div>
                            <div className={archiveLinkWrapper}>
                              <Link to="/maine_digest_archives">
                                <div className={archiveLink}>See older items</div>
                              </Link>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                  





                  <div className={carouselSlide}>
                    <div className="News" style={{ paddingTop: '2rem', minHeight: '600px' }}>
                      <h2 className="News-title">Latest News</h2>
                      <div className="News-item">
                        <div className="News-image">
                          {/* 611 x 382 */}
                          <img width="611" height="382" src="https://network.bendable.com/wp-content/uploads/2021/09/dwp_amc_gorman_091215-195.jpg" alt="person on boat dock" />
                        </div>
                        <div className="News-body">
                          <div className="News-content">
                            <p className={dateline}>June 2022</p>
                            <h2 className={latestnewsarticleheadline}>Bendable Maine Digest</h2>
                            <p className={subhead}>A monthly update on the lifelong learning system being shaped by Mainers for Mainers</p>
                            <div className="News-actions">
                              <Link to="/maine_digest_2022_06">
                                <div className="Button">Read more</div>
                              </Link>
                            </div>
                            <div className={archiveLinkWrapper}>
                              <Link to="/maine_digest_archives">
                                <div className={archiveLink}>See older items</div>
                              </Link>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div className={carouselSlide}>
                    <div className="News" style={{ paddingTop: '2rem', minHeight: '600px' }}>
                      <h2 className="News-title">Latest News</h2>
                      <div className="News-item">
                        <div className="News-image">
                          <img width="611" height="382" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Fishnet.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Fishnet.png 611w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-Fishnet-300x188.png 300w" sizes="(max-width: 611px) 100vw, 611px" />
                        </div>
                        <div className="News-body">
                          <div className="News-content">
                            <p className={dateline}>May 2022</p>
                            <h2 className={latestnewsarticleheadline}>Bendable Maine Digest</h2>
                            <p className={subhead}>A monthly update on the lifelong learning system being shaped by Mainers for Mainers</p>
                            <div className="News-actions">
                              <Link to="/maine_digest_2022_05">
                                <div className="Button">Read more</div>
                              </Link>
                            </div>
                            <div className={archiveLinkWrapper}>
                              <Link to="/maine_digest_archives">
                                <div className={archiveLink}>See older items</div>
                              </Link>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>






                </Carousel>
              </div>
            </div>

          </article>
        </main>

        <footer id="colophon" className="Site-footer">
          <div className="Site-info">
            <div className="Site-logos">
              <a href="https://www.maine.gov/msl/" target="_blank" rel="noreferrer">
                <img width="146" height="146" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-State-Library-Logo.png" className="attachment-full size-full" alt="" loading="lazy" />
              </a>

              <div style={{ width: '60px', height: '30px' }} />
              
              <BendableLabsLogo
                width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
              />

            </div>
            
          </div>
          
          {/* <nav id="site-navigation" className="Site-navigation">
            <div className="menu-menu-container">
              <ul id="footer-menu" className="menu"><li id="menu-item-200" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-200"><a href="https://drucker.institute/programs/city-of-lifelong-learning/">Network</a></li>
                <li id="menu-item-201" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-201"><a href="mailto:support@bendable.com">Tech Support</a></li>
                <li id="menu-item-202" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-202"><a href="https://network.bendable.com/privacy-policy">Privacy Policy</a></li>
                <li id="menu-item-203" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"><a href="https://network.bendable.com/terms-of-service">Terms of Service</a></li>
              </ul>
            </div>
          </nav> */}
          
        </footer>
      </div>

      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/navigation.js?ver=1.0.0' id='bendable-navigation-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/flickity.js?ver=2.2' id='flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/mobile-flickity-slider.js?ver=2.2' id='custom-flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/wp-embed.min.js?ver=5.7.2' id='wp-embed-js'></script>
    </Layout>
  )
}

export default Maine;